import Link from 'gatsby-plugin-transition-link'
import React from 'react'

import '../styles/header.sass'

const AniLink = Link

const Header = ({languageSwitchLink}) => (<header>
    <div className="header-container">

        <ul className="header-nav">
            <li>
                <AniLink fade duration={0.2} to="/" activeClassName="active" className="hvr-underline-from-center">
                    ACCUEIL
                </AniLink>
            </li>
            <li>
                <AniLink fade duration={0.2} to="/about" activeClassName="active"
                    className="hvr-underline-from-center">
                    À PROPOS
                </AniLink>
            </li>
            <li>
                <AniLink fade duration={0.2} to="/ctf" activeClassName="active"
                    className="hvr-underline-from-center">
                    CTF
                </AniLink>
            </li>
            <li>
                <AniLink fade duration={0.2} to="/events" activeClassName="active"
                    className="hvr-underline-from-center">
                    ACTIVITÉS
                </AniLink>
            </li>
            <li>
                <AniLink fade duration={0.2} to="/blog" activeClassName="active"
                    className="hvr-underline-from-center">
                    BLOG
                </AniLink>
            </li>
            <li>
                <AniLink fade duration={0.2} to="/team" activeClassName="active"
                    className="hvr-underline-from-center">
                    L&apos;ÉQUIPE
                </AniLink>
            </li>
            <li>
                <AniLink fade duration={0.2} to="/partners" activeClassName="active"
                    className="hvr-underline-from-center">
                    PARTENAIRES
                </AniLink>
            </li>
            <li>
                <AniLink fade duration={0.2}
                    to={languageSwitchLink}
                    activeClassName="active"
                    className="language-switch-link">
                    EN
                </AniLink>
            </li>
        </ul>
    </div>
</header>)

export default Header
